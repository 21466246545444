import AnchorText from "./AnchorText";
import Button from "./Button";
import IconAccordionOff from "@/assets/img/ico_gnb_accordion_off@2x.png";
import IconAccordionOn from "@/assets/img/ico_gnb_accordion_on@2x.png";
import IconClose from "@/assets/img/ico_gnb_close@2x.png";
import IconFaq from "@/assets/img/ico_gnb_faq@2x.png";
import IconHeart from "@/assets/img/ico_gnb_heart@2x.png";
import IconHome from "@/assets/img/ico_gnb_home@2x.png";
import IconMypage from "@/assets/img/ico_gnb_mypage@2x.png";
import IconPayment from "@/assets/img/ico_gnb_payment@2x.png";
import SearchButtonIcon from "@/assets/img/search-button-mobile.png";
import { Route, ServerRoute, serverToRoute } from "@/config/routes";
import { useUserStore } from "@/stores/UserStore";
import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import { observer } from "mobx-react-lite";
import React, { FC, useCallback, useState } from "react";
import styled from "styled-components";

const MobileAllmenu = styled.div<{ isShow: boolean }>`
  display: ${({ isShow }) => (isShow ? `block` : `none`)};
  position: fixed;
  width: 100%;
  min-height: 100%;
  overflow: scroll;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 99999;
`;
const MobileTopArea = styled.div`
  padding: 20px;
  background: #fff;
`;
const MobileUserInfo = styled.div`
  position: relative;
  height: 24px;
  line-height: 24px;

  .mobile--user-info {
    &.isLoggedIn {
      font-weight: bold;
      font-size: 16px;

      span {
        color: #1cabe2;
      }
    }

    &.isLoggedOut {
      font-size: 14px;
      text-decoration: underline;
    }
  }

  .mobile--menu-close {
    width: 110px;
    position: absolute;
    right: 0;
    top: 0;

    .mobile--home {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url(${IconHome}) no-repeat center center;
      background-size: 100%;
    }

    .mobile--search {
      display: inline-block;
      width: 20px;
      height: 20px;
      padding: 2px;
      background: url(${SearchButtonIcon}) no-repeat center center;
      background-size: 100%;
      border: none;
      margin: 2px 0 0 16px;
      vertical-align: top;
    }

    .mobile--close {
      display: inline-block;
      width: 20px;
      height: 20px;
      padding: 2px;
      background: url(${IconClose}) no-repeat center center;
      background-size: 100%;
      border: none;
      margin: 2px 0 0 16px;
      vertical-align: top;
    }
  }
`;
const MobileMyMenu = styled.div`
  margin: 30px 0 18px 0;

  ul {
    font-size: 0;
    text-align: center;

    li {
      width: 25%;
      display: inline-block;
      font-size: 12px;

      span {
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        border: 1px solid #1cabe2;
        margin: 0 auto 7px;
        background-size: 24px;
        background-position: center center;
        background-repeat: no-repeat;

        &.mymenu-icon-01 {
          background-image: url(${IconMypage});
        }

        &.mymenu-icon-02 {
          background-image: url(${IconPayment});
        }

        &.mymenu-icon-03 {
          background-image: url(${IconHeart});
        }

        &.mymenu-icon-04 {
          background-image: url(${IconFaq});
        }
      }
    }
  }
`;
const MobileBill = styled.div`
  display: block;

  button {
    width: 100%;
  }
`;
const MobileAccordionMenu = styled.div`
  display: block;
  height: 100%;

  ${AnchorText} {
    position: fixed;
    left: 20px;
    bottom: 24px;
    z-index: 2;
    color: #e5e6e8;
  }
`;
const MobileOneDepth = styled.div`
  width: 38vw;
  position: absolute;
  left: 0;
  height: 100%;
  background: #1cabe2;

  li {
    height: 64px;
    line-height: 64px;
    padding: 0 0 0 20px;
    font-size: 16px;
  }
`;
const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 16px;
`;
const OneDepthList = styled(List)``;
const OneDepthItem = styled.li<{ active?: boolean }>`
  color: ${(props) => (props.active === true ? `#1cabe2` : `#fff`)};
  background-color: ${(props) => (props.active === true ? `#fff` : `#1cabe2`)};
`;
const OneDepthLink = styled(({ active, className, ...props }) => (
  <span {...props} className={className + (active ? ` active` : ``)} />
))`
  font-weight: bold;
  font-size: 16px;

  &.active {
    color: #1cabe2;
  }
`;
const TwoDepthSection = styled.div<{ active?: boolean }>`
  ${(props) => (props.active ? `` : `display: none;`)}
  background-color: #fff;
  display: inline-block;
  width: 100%;
  padding-left: 38vw;
`;
const TwoDepthList = styled(List)``;
const TwoDepthItem = styled.li`
  span {
    font-size: 14px;
    display: block;
    line-height: 64px;
    padding: 0 20px;
  }
`;
const TwoDepthLink = styled.span<{
  active?: boolean;
}>`
  display: block;
  font-weight: bold;

  ${(props) => (props.active ? `color: #1cabe2;` : ``)}
  &.type-link {
    ${(props) =>
      props.active
        ? `background-image: url(${IconAccordionOn});`
        : `background-image: url(${IconAccordionOff});`}
    background-size: 24px;
    background-position: right 20px center;
    background-repeat: no-repeat;
  }
}
`;
const ThreeDepthList = styled(List)<{ active?: boolean }>`
  ${(props) => (props.active ? `` : `display: none;`)}
  margin-bottom: 16px;
`;
const ThreeDepthItem = styled.li``;
const ThreeDepthLink = styled((props) => <Link {...props} />)<{
  active?: boolean;
}>`
  padding: 0 20px;
  font-size: 14px;
  line-height: 24px;
  margin-top: 8px;

  &:hover {
    color: #1cabe2;
  }
`;

interface HeaderProps {
  routePath?: Route[];
  isShow: boolean;
  onMenuClose?: () => void;
}

// 헤더
const Allmenu: FC<HeaderProps> = observer(
  ({ routePath, isShow, onMenuClose }) => {
    const [depthRoute, setDepthRoute] = useState(0);
    const [active, setActive] = useState(0);
    const userStore = useUserStore();
    const {
      allMenu: { nodes: serverMenus }
    } = useStaticQuery<{
      allMenu: { nodes: ServerRoute[] };
    }>(graphql`
      query {
        allMenu(sort: { fields: [id], order: [ASC] }) {
          nodes {
            id
            level
            menuCode
            menuName
            menuUrl
            offspring {
              level
              menuCode
              menuParentCode
              menuColumnOrder
              menuName
              menuUrl
              offspring {
                level
                menuCode
                menuName
                menuParentCode
                menuUrl
              }
            }
          }
        }
      }
    `);
    const menus = serverMenus.map(serverToRoute);
    const [selectedRoute, setSelectedRoute] = useState<Route>(menus[0]);

    const onCloseRequest = useCallback(() => {
      onMenuClose?.();
    }, [onMenuClose]);

    return (
      <>
        <MobileAllmenu isShow={isShow}>
          <MobileTopArea>
            <MobileUserInfo>
              {userStore.isLoggedIn() ? (
                <div className="mobile--user-info isLoggedIn">
                  <span>{userStore.user.name}</span> 후원자님
                </div>
              ) : (
                <div className="mobile--user-info isLoggedOut">
                  <Link to="/auth/login">로그인</Link>
                </div>
              )}

              <div className="mobile--menu-close">
                <Link to="/" className="mobile--home">
                  <span className="for-a11y">메인으로 가기</span>
                </Link>

                <Link to="/search" className="mobile--search">
                  <span className="for-a11y">검색 하기</span>
                </Link>

                <button
                  type="button"
                  className="mobile--close"
                  onClick={onCloseRequest}
                >
                  <span className="for-a11y">모바일 전체메뉴 닫기</span>
                </button>
              </div>
            </MobileUserInfo>
            <MobileMyMenu>
              {userStore.isLoggedIn() ? (
                <ul>
                  <li className="mobile--mymenu-01">
                    <Link to="/mypage">
                      <span className="mymenu-icon-01" />
                      마이페이지
                    </Link>
                  </li>
                  <li className="mobile--mymenu-02">
                    <Link to="/mypage/support-info">
                      <span className="mymenu-icon-02" />
                      후원/결제정보
                    </Link>
                  </li>
                  <li className="mobile--mymenu-03">
                    <Link to="/mypage/support-history">
                      <span className="mymenu-icon-03" />
                      후원내역
                    </Link>
                  </li>
                  <li className="mobile--mymenu-04">
                    <Link to="/faq">
                      <span className="mymenu-icon-04" />
                      FAQ
                    </Link>
                  </li>
                </ul>
              ) : (
                <ul>
                  <li className="mobile--mymenu-01">
                    <Link to="/auth/login?redirectTo=/mypage">
                      <span className="mymenu-icon-01" />
                      마이페이지
                    </Link>
                  </li>
                  <li className="mobile--mymenu-02">
                    <Link to="/auth/login?redirectTo=/mypage/support-info">
                      <span className="mymenu-icon-02" />
                      후원/결제정보
                    </Link>
                  </li>
                  <li className="mobile--mymenu-03">
                    <Link to="/auth/login?redirectTo=/mypage/support-history">
                      <span className="mymenu-icon-03" />
                      후원내역
                    </Link>
                  </li>
                  <li className="mobile--mymenu-04">
                    <Link to="/faq">
                      <span className="mymenu-icon-04" />
                      FAQ
                    </Link>
                  </li>
                </ul>
              )}
            </MobileMyMenu>
            <MobileBill>
              {userStore.isLoggedIn() ? (
                <Button outline onClick={() => navigate(`/mypage/my-receipt`)}>
                  기부금 영수증 신청
                </Button>
              ) : (
                <Button
                  outline
                  onClick={() =>
                    navigate(`/auth/login?redirectTo=/mypage/my-receipt`)
                  }
                >
                  기부금 영수증 신청
                </Button>
              )}
            </MobileBill>
          </MobileTopArea>
          <MobileAccordionMenu>
            {userStore.isLoggedIn() && (
              <AnchorText onClick={() => userStore.logout()}>
                로그아웃
              </AnchorText>
            )}
            <MobileOneDepth>
              <OneDepthList>
                {menus.map((primary, index) => (
                  <OneDepthItem
                    key={primary.id}
                    active={index === active}
                    onClick={() => {
                      setSelectedRoute(primary);
                      setActive(index);
                      setDepthRoute(0);
                    }}
                  >
                    <OneDepthLink active={routePath?.[0]?.id === primary.id}>
                      {primary.name}
                    </OneDepthLink>
                  </OneDepthItem>
                ))}
              </OneDepthList>
            </MobileOneDepth>
            {selectedRoute?.children?.map((secondary, index) => (
              <TwoDepthSection key={secondary.id}>
                <TwoDepthList>
                  <TwoDepthItem>
                    {secondary?.children ? (
                      <TwoDepthLink
                        className="type-link"
                        active={index === depthRoute}
                        onClick={() => {
                          setDepthRoute(index);
                        }}
                      >
                        {secondary.name}
                      </TwoDepthLink>
                    ) : (
                      <TwoDepthLink
                        active={index === depthRoute}
                        onClick={() => {
                          setDepthRoute(index);
                        }}
                      >
                        <Link to={secondary.link}>{secondary.name}</Link>
                      </TwoDepthLink>
                    )}
                    <ThreeDepthList active={depthRoute === index}>
                      {secondary?.children?.map((tertiary) => (
                        <ThreeDepthItem key={tertiary.id}>
                          <ThreeDepthLink to={tertiary.link}>
                            {tertiary.name}
                          </ThreeDepthLink>
                        </ThreeDepthItem>
                      ))}
                    </ThreeDepthList>
                  </TwoDepthItem>
                </TwoDepthList>
              </TwoDepthSection>
            ))}
          </MobileAccordionMenu>
        </MobileAllmenu>
      </>
    );
  }
);

export default Allmenu;
