import SkyArrow from '@/assets/img/arrow_ellipsis_24x24@2x.png';
import WhiteArrow from '@/assets/img/arrow_white_24x24@2x.png';
import Cart from '@/assets/img/icons/icon_cart@2x.png';
import Menu from '@/assets/img/icons/icon_menu@2x.png';
import ArrowSky from '@/assets/img/icons/ico_arrow_sky@2x.png';
import Download from '@/assets/img/icons/ico_down@2x.png';
import SkyBottomArrow from '@/assets/img/icons/ico_sky_bottom_arrow@2x.png';
import TargetBlank from '@/assets/img/icons/ico_target_blank@2x.png';
import PaginationFirst from '@/assets/img/ico_pagination_first@2x.png';
import PaginationLast from '@/assets/img/ico_pagination_last@2x.png';
import PaginationNext from '@/assets/img/ico_pagination_next@2x.png';
import PaginationPrev from '@/assets/img/ico_pagination_prev@2x.png';
import styled from 'styled-components';

const IMAGES = {
  'white-arrow': WhiteArrow,
  'sky-arrow': SkyArrow,
  'sky-bottom': SkyBottomArrow,
  cart: Cart,
  menu: Menu,
  arrowSky: ArrowSky,
  download: Download,
  target: TargetBlank,
  'pagination-first': PaginationFirst,
  'pagination-prev': PaginationPrev,
  'pagination-next': PaginationNext,
  'pagination-last': PaginationLast,
};

const ImageIcon = styled.i<{ name: keyof typeof IMAGES }>`
  background-image: url(${({ name }) => IMAGES[name]});
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  vertical-align: middle;
  display: inline-block;
  width: 24px;
  height: 24px;
`;

export default ImageIcon;
