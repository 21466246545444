import { breakpoint } from '@/helpers/BreakpointHelper';
import styled from 'styled-components';

/**
 * a태그로 만들던 버튼기능을 button으로 만듦 (eslint 추천사항)
 */
const AnchorText = styled.button.attrs({ type: `button` })<{
  color?: string | boolean;
}>`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.8px;
  color: ${({ color }) => (color ? `#1cabe2` : `#828385`)};
  text-decoration: underline;
  :hover {
    text-decoration: underline;
  }
  ${breakpoint(`mobile`)} {
    font-size: 14px;
  }
`;

export default AnchorText;
