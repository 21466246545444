import Button from './Button';
import { GtmButtonDonate } from './Button.gtm';
import Container from './Container';
import ImageIcon from './ImageIcon';
import ReceiptPopup from './Layer/ReceiptPopup';
import LinkSafe from './LinkSafe';
import Section from './Section';
import ActiveHeaderLogo from '@/assets/img/logo_active@2x.png';
import ActiveHeader30thLogo from '@/assets/img/logo_active_30th@2x.png';
import HeaderLogo from '@/assets/img/logo_white@2x.png';
import SearchButtonIcon from '@/assets/img/search-button.png';
import Row from '@/components/Grid/Row';
import Allmenu from '@/components/MobileMenu';
import { Route, ServerRoute, serverToRoute } from '@/config/routes';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { isBrowser } from '@/helpers/BrowserHelper';
import { useUserStore } from '@/stores/UserStore';
import { graphql, Link, navigate, useStaticQuery } from 'gatsby';
import { throttle } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { SelectItemDefaultData } from '@/helpers/GtmHelper';

interface HeaderProps {
  transparent?: boolean;
  routePath?: Route[];
  hideGnb?: boolean;
}

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const StyledLink = styled((props) => <Link {...props} />)`
  transition: all 0.2s;
`;

const HeaderInner = styled.div`
  height: 100%;
  border-bottom: 1px solid transparent;
  transition: all 0.2s;
  ${breakpoint(`tablet`)} {
    padding: 0px;
  }
`;

// Top Nav
const TopList = styled(List)`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 10px;
  width: 1120px;
  ${breakpoint(`desktop`)} {
    width: auto;
    right: 20px;
  }
  li + li {
    padding-left: 8px;
    margin-left: 8px;
    a,
    button {
      &:hover {
        color: #1cabe2;
      }
    }
    &::before {
      content: '';
      display: block;
      width: 1px;
      height: 11px;
      position: absolute;
      left: 0;
      top: 6px;
      background-color: #b7b8ba;
    }
  }
`;
const TopItem = styled.li<{ color?: string }>`
  font-size: 0.9em;
  cursor: pointer;
  position: relative;
  ${({ color }) => color && `color: ${color};`}
  button {
    vertical-align: baseline;
  }

  // 강조 효과 css 추가
  .topActive {
    position: relative;

    ::after {
      content: '';
      display: block;
      width: 100%;
      height: 18px;
      background: #1cabe2;
      position: absolute;
      left: 0;
      bottom: 0px;
      z-index: -1;
    }
    :hover {
      color: white;
    }
  }
`;

const Logo = styled.h1`
  // IE11 때문에 table로 설정. block이 안되는 원인은 모르겠음...
  display: table;
  width: 182px;
  margin-top: 0px;

  ${breakpoint(`tablet`)} {
    width: 140px;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);
    margin-top: 0;
  }

  ${breakpoint(`mobile`)} {
    width: 113px;
  }
`;
const LogoLink = styled((props) => <Link {...props} />)`
  display: block;
  width: 100%;
  padding-top: 75px;
  background: url(${HeaderLogo}) no-repeat center center;
  background-size: contain;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 174px;
    height: 174px;
    background-color: #009ee2;
    border-radius: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -87px;
    margin-top: -87px;
    z-index: -1;
    // 마우스 호버 disable (로고 밖 영역 이슈)
    pointer-events: none;
  }
`;

const headerActive = css`
  background-color: #fff;
  color: inherit;

  ${LogoLink} {
    /*
    background-image: url(${ActiveHeaderLogo});
    */
    background-image: url(${ActiveHeader30thLogo});
    &::after {
      background-color: transparent;
    }
  }
  a {
    color: inherit;
    &:hover {
      color: #1cabe2;
    }
  }
  ${HeaderInner} {
    border-bottom: 1px solid #e0e0e0;
  }
`;

const headerSticky = css`
  position: fixed;
  padding-top: 0px;
  ${HeaderInner} {
    padding: 20px 0 0 0;
  }
  ${TopList} {
    display: none;
  }
`;

// Header 최상위 컴포넌트
const HeaderContainer = styled.header<{
  transparent: boolean;
  sticky: boolean;
  hideGnb?: boolean;
}>`
  position: absolute;
  z-index: 50;
  width: 100%;
  height: auto;
  transition: background 0.2s, padding 0.2s;
  color: #fff;
  padding-top: 50px;
  & a {
    color: #fff;
  }
  // 기부금 영수증 문구 기본 흰색으로 변경
  .topActive {
    color: #fff;
  }
  &:hover {
    ${headerActive}
  }

  ${({ sticky, transparent }) => (sticky || !transparent) && headerActive}
  ${({ sticky }) => sticky && headerSticky}

  ${breakpoint(`tablet`)} {
    padding: 0;
    height: 70px;
    overflow: hidden;
    ${headerActive}
    ${TopList} {
      display: none;
    }
    ${HeaderInner} {
      padding: 0;
      padding-right: 70px;
    }
  }

  ${breakpoint(`mobile`)} {
    height: 56px;
    overflow: hidden;
    ${headerActive}
    ${TopList} {
      display: none;
    }
    ${HeaderInner} {
      padding-right: 56px;
    }
  }
`;

const NavContainer = styled(Container)`
  width: 1160px;
  height: 100%;
  width: 100%;

  ${breakpoint(`tablet`)} {
    padding: 0;
  }
`;

// Main Nav
const MainNavigation = styled(Row)`
  margin-bottom: 24px;
  ${breakpoint(`mobile`)} {
    height: 56px;
  }
`;
const PrimaryList = styled(List)`
  white-space: nowrap;
  margin-left: 40px;

  ${breakpoint(1160)} {
    margin-left: 9.66vw;
  }
  ${breakpoint(`tablet`)} {
    display: none;
  }
`;
const PrimaryItem = styled.li`
  display: inline-block;
  margin-right: 48px;

  ${breakpoint(1160)} {
    margin-right: 4.14vw;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const ActiveLink = styled(({ active, className, ...props }) => (
  <LinkSafe {...props} className={className + (active ? ` active` : ``)} />
))`
  &.active {
    color: #1cabe2 !important;
  }
`;

const PrimaryLink = styled(ActiveLink)`
  font-weight: bold;
  font-size: 20px;
`;
const SecondarySection = styled(Section)<{ active?: boolean }>`
  ${(props) => (props.active ? `` : `display: none;`)}
  background-color: #fff;
`;
const SecondaryList = styled(List)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: start;
  padding: 20px 120px 20px 0;
  ${breakpoint(1024)} {
    padding: 20px 80px 20px 0;
  }
`;
const SecondaryColumn = styled.li`
  & + & {
    margin-left: 80px;
    ${breakpoint(1120)} {
      margin-left: 50px;
    }
  }
`;
const SecondaryItem = styled.div``;
const SecondaryLink = styled(ActiveLink)`
  display: block;
  padding: 0 0 12px 0;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.8px;
`;
const TertiaryList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 0 20px 0;
`;
const TertiaryItem = styled.li`
  & + & {
    margin-top: 4px;
  }
`;
const TertiaryLinkActive = css`
  color: #2d2926 !important;
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 8px;
    background: #b2def1;
    position: absolute;
    left: 0;
    bottom: 4px;
    z-index: -1;
  }
`;
const TertiaryLink = styled((props) => <Link {...props} />)<{
  active?: boolean;
}>`
  padding: 5px 0;
  color: #2d2926;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.8px;
  position: relative;
  z-index: 1;
  &:hover {
    ${TertiaryLinkActive}
  }
  ${({ active }) => active && TertiaryLinkActive}
`;

const SupportButton = styled(Button)`
  width: 160px;
  height: 64px;
  ${breakpoint(`tablet`)} {
    height: 70px;
    font-size: 16px;
  }
  ${breakpoint(`mobile`)} {
    width: 136px;
    height: 56px;
    font-size: 16px;
  }
`;

const MenuButton = styled(Button)`
  display: none;
  ${breakpoint(`tablet`)} {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 70px;
    height: 70px;
    ${ImageIcon} {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  ${breakpoint(`mobile`)} {
    width: 56px;
    height: 56px;
  }
`;

const MobileHiddenMenu = styled.div`
  background-color: #1cabe2;
  width: 100%;
  height: 40px;
  color: #fff;
  text-align: center;
  padding-top: 6px;
  display: none;

  ${breakpoint(`mobile`)} {
    display: block;
  }
`;

const SearchForm = styled.form`
  position: relative;
  width: 160px;
  margin: auto;
`;

const SearchBar = styled.input`
  display: block;
  margin: auto;
  border: 1px solid #aaa;
  margin-bottom: 30px;
  height: 28px;
  min-width: 160px;
  color: #333333;
  font-size: 14px;
  letter-spacing: -0.05em;
  font-family: 'Nanum Barun Gothic';
  outline: none;
  padding: 0 10px;
  padding-top: 1px;
`;

const SearchButton = styled.button`
  position: absolute;
  top: 2px;
  right: 10px;
`;

// 헤더
const Header: FC<HeaderProps> = observer(
  ({ transparent, routePath, hideGnb }) => {
    const [isSticky, setSticky] = useState(false);
    const [selectedRoute, setSelectedRoute] = useState<Route>();
    const elementRef = useRef<HTMLUListElement>(null);
    const userStore = useUserStore();
    const {
      allMenu: { nodes: serverMenus },
    } = useStaticQuery<{
      allMenu: { nodes: ServerRoute[] };
    }>(graphql`
      query {
        allMenu(sort: { fields: [id], order: [ASC] }) {
          nodes {
            id
            level
            menuCode
            menuName
            menuUrl
            openYn
            offspring {
              level
              menuCode
              menuParentCode
              menuColumnOrder
              menuName
              menuUrl
              openYn
              offspring {
                level
                menuCode
                menuName
                menuParentCode
                menuUrl
                openYn
              }
            }
          }
        }
      }
    `);
    const menus = serverMenus.map(serverToRoute);

    const handleScroll = throttle(() => {
      if (isBrowser) {
        setSticky(window.scrollY > 0);
      }
    }, 20);

    useEffect(() => {
      // 모바일일 경우만 메뉴 띄움
      /*
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        )
      ) {
        document.getElementById(`mobileTmpMenu`).style.display = ``;
      } else {
        document.getElementById(`mobileTmpMenu`).style.display = `none`;
      }
      */
      window.addEventListener(`scroll`, handleScroll);
      return () => {
        window.removeEventListener(`scroll`, handleScroll);
      };
    }, [handleScroll]);

    const [menuOpen, setMenuOpen] = useState(false);

    const show = () => setMenuOpen(true);
    const hide = () => setMenuOpen(false);

    const [popupOpen, setPopupOpen] = useState(false);
    const [PopupTitle, setPopupTitle] = useState(``);

    const open = () => setPopupOpen(true);
    const close = () => setPopupOpen(false);

    const pathname =
      typeof window !== `undefined` ? window.location.pathname : ``;

    return (
      <>
        <HeaderContainer
          ref={elementRef}
          sticky={isSticky}
          transparent={!!transparent}
          hideGnb={!!hideGnb}
          onMouseLeave={() => setSelectedRoute(undefined)}
        >
          <HeaderInner>
            <NavContainer>
              {!hideGnb && (
                <TopList>
                  {userStore.isLoggedIn() ? (
                    <TopItem onClick={() => userStore.logout()}>
                      로그아웃
                    </TopItem>
                  ) : (
                    <TopItem>
                      <StyledLink
                        to="/auth/login"
                        state={{ prevPath: pathname }}
                      >
                        로그인
                      </StyledLink>
                    </TopItem>
                  )}
                  {userStore.isLoggedIn() ? (
                    <TopItem>
                      <StyledLink to="/mypage">마이페이지</StyledLink>
                    </TopItem>
                  ) : (
                    <TopItem>
                      <StyledLink to="/auth/join">회원가입</StyledLink>
                    </TopItem>
                  )}
                  <TopItem>
                    <button
                      className="topActive"
                      type="button"
                      onClick={() => {
                        open();
                        setPopupTitle(`기부금 영수증 안내`);
                      }}
                    >
                      기부금 영수증
                    </button>
                    <ReceiptPopup
                      isOpen={popupOpen}
                      PopTitle={PopupTitle}
                      onRequestClose={close}
                    />
                  </TopItem>
                  <TopItem>
                    <StyledLink to="/faq">FAQ</StyledLink>
                  </TopItem>
                  {/* 개발서버 기능 추가 후 반영 */}
                  <TopItem>
                    <SearchForm action="/search">
                      <SearchBar id="searchText" name="q" />
                      <input
                        type="hidden"
                        id="cx"
                        name="cx"
                        value="000556451291554713052:lnlt2cb2m7e"
                      />

                      <SearchButton
                        type="submit"
                        name="search-button"
                        title="검색하기"
                      >
                        <button
                          type="submit"
                          name="search-button"
                          title="검색하기"
                        >
                          <img
                            src={SearchButtonIcon}
                            style={{ width: `14px` }}
                            alt="검색버튼"
                          />
                        </button>
                      </SearchButton>
                    </SearchForm>
                  </TopItem>
                </TopList>
              )}
              <MainNavigation>
                <Row justifyContent="flex-start">
                  <Logo>
                    <LogoLink to="/">
                      <span className="for-a11y">유니세프</span>
                    </LogoLink>
                  </Logo>
                  {!hideGnb && (
                    // 1depth메뉴
                    <PrimaryList>
                      {menus.map((primary) => (
                        <PrimaryItem
                          key={primary.id}
                          onMouseEnter={() => setSelectedRoute(primary)}
                        >
                          <PrimaryLink
                            to={primary.link}
                            active={routePath?.[0]?.id === primary.id}
                          >
                            {primary.name}
                          </PrimaryLink>
                        </PrimaryItem>
                      ))}
                    </PrimaryList>
                  )}
                </Row>
                <Row justifyContent="flex-end">
                  {!hideGnb && (
                    <>
                      <GtmButtonDonate
                        type="button"
                        text="후원하기"
                        color="yellow"
                        className="btn-support-gnb"
                        goto="/donate"
                        gtmInfo={{
                          ...SelectItemDefaultData,
                          campaignName: 'direct',
                          promotionName: 'direct',
                          itemName: '',
                          itemCategory: '',
                          itemListName: '',
                          itemBreadscrumb: 'gnb',
                        }}
                        onClickPreEvent={() => {
                          // TODO onClick before call gtm
                          // alert('click');
                        }}
                      />
                      <MenuButton onClick={show}>
                        <ImageIcon name="menu" />
                      </MenuButton>
                    </>
                  )}
                </Row>
                <Allmenu isShow={menuOpen} onMenuClose={hide} />
              </MainNavigation>
            </NavContainer>
          </HeaderInner>
          {/* selectedRoute가 있으면 active */}
          <SecondarySection active={!!selectedRoute}>
            <Container
              css={`
                padding-left: 52px;
              `}
            >
              <SecondaryList>
                {[0, 1, 2, 3, 4].map((col) => {
                  // 0번 col에는 colIndex가 없거나, 0인 항목이 들어감
                  const items =
                    col === 0
                      ? selectedRoute?.children?.filter(
                          (item) => !item.colIndex,
                        )
                      : selectedRoute?.children?.filter(
                          (item) => col === item.colIndex,
                        );
                  return (
                    <SecondaryColumn key={col}>
                      {items?.map((secondary) => (
                        <SecondaryItem key={secondary.id}>
                          <SecondaryLink
                            to={secondary.link}
                            active={routePath?.[1]?.id === secondary.id}
                            target={secondary.newtab === `Y` ? `_blank` : null}
                          >
                            {secondary.name}
                          </SecondaryLink>
                          {secondary.children && (
                            <TertiaryList>
                              {secondary.children.map((tertiary) => (
                                <TertiaryItem key={tertiary.id}>
                                  <TertiaryLink
                                    to={tertiary.link}
                                    active={routePath?.[2]?.id === tertiary.id}
                                    target={
                                      tertiary.newtab === `Y` ? `_blank` : null
                                    }
                                  >
                                    {tertiary.name}
                                  </TertiaryLink>
                                </TertiaryItem>
                              ))}
                            </TertiaryList>
                          )}
                        </SecondaryItem>
                      ))}
                    </SecondaryColumn>
                  );
                })}
              </SecondaryList>
            </Container>
          </SecondarySection>
        </HeaderContainer>
        {userStore.isLoggedIn() ? (
          <MobileHiddenMenu>
            <span>
              <a href="/involve/individual/pledge">후원 안내</a>
            </span>
            <span style={{ marginLeft: `12px`, marginRight: `12px` }}>｜</span>
            <span>
              <a href="/mypage">마이페이지</a>
            </span>
            <span style={{ marginLeft: `12px`, marginRight: `12px` }}>｜</span>
            <span>
              <a href="/mypage/my-receipt">
                기부금 영수증
                <sup>
                  <b> •</b>
                </sup>
              </a>
            </span>
            <span style={{ marginLeft: `12px`, marginRight: `12px` }}>｜</span>
            <span>
              <a href="/faq">FAQ</a>
            </span>
          </MobileHiddenMenu>
        ) : (
          <MobileHiddenMenu>
            <span>
              <Link to="/involve/individual/pledge">후원 안내</Link>
            </span>
            <span style={{ marginLeft: `12px`, marginRight: `12px` }}>｜</span>
            <span>
              <Link to="/auth/login">로그인</Link>
            </span>
            <span style={{ marginLeft: `12px`, marginRight: `12px` }}>｜</span>
            <span>
              <Link to="/mypage">마이페이지</Link>
            </span>
            <span style={{ marginLeft: `12px`, marginRight: `12px` }}>｜</span>
            <span>
              <Link to="/mypage/my-receipt">
                기부금 영수증
                <sup>
                  <b> •</b>
                </sup>
              </Link>
            </span>
          </MobileHiddenMenu>
        )}
      </>
    );
  },
);

export default Header;
