import { breakpoint } from '@/helpers/BreakpointHelper';
import { ImgHTMLAttributes } from 'react';
import styled from 'styled-components';

const Section = styled.section<{
  backgroundImage?: ImgHTMLAttributes<any>['src'];
  backgroundColor?: string;
  paddingTop?: string;
  marginTop?: string;
  borderBottom?: boolean;
}>`
  display: block;
  ${({ backgroundImage }) =>
    backgroundImage
      ? `
    background-image: url(${backgroundImage});
    background-position: center;
    background-size: cover;
    `
      : ``}
  ${({ backgroundColor }) =>
    backgroundColor ? `background-color: ${backgroundColor};` : ``}
  ${({ paddingTop }) => (paddingTop ? `padding-top: ${paddingTop};` : ``)}
  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop};` : ``)}
  ${({ borderBottom }) => borderBottom && `border-bottom: 1px solid #e5e6e8;`}

  &.by-sub-main-layout {
    width: 100%;
    position: relative;
    padding: 120px 0;
    &.pdt-0 {
      padding: 0 0 120px 0;
    }

    &:first-of-type {
      padding-top: 96px;
    }

    ${breakpoint(`tablet`)} {
      padding: 60px 0;

      &:first-of-type {
        padding-top: 72px;
      }
    }
  }
`;

export default Section;

export const SectionNews = styled(Section)`
  .list-item {
    width: 25%;
  }

  ${breakpoint(`mobile`)} {
    .list-item {
      width: 50%;
    }
  }
`;
export const SectionContact = styled(Section)``;
