import ButtonMoreView from '../ButtonMoreView';
import { PopCcontainer, PopupTitle } from '../Css/Pop';
import Modal from '../Modal';
import { Tit } from '../Titles';
import ReceiptObj1 from '@/assets/img/layer/ReceiptPopup_obj1@2x.png';
import ReceiptObj2 from '@/assets/img/layer/ReceiptPopup_obj2@2x.png';
import { BtnClose } from '@/components/Button';
import Image from '@/components/Image';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { useUserStore } from '@/stores/UserStore';
import { navigate } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';

export interface ModalProps {
  isOpen?: boolean;
  // 모달 바깥쪽 누를시 닫을수 있는지 여부
  dismissable?: boolean;
  // 닫힘요청시 콜백
  onRequestClose: () => void;
  maxWidth?: string;
}

interface ReceiptProps extends ModalProps {
  PopTitle: string;
}

const TopFlex = styled.ul`
  display: flex;
  align-items: stretch;
  margin: 0 -12px;
  margin-top: 48px;

  & > li {
    width: 50%;
    padding: 0 12px;
  }

  .box {
    height: 100%;
    border: 1px solid #e5e6e8;
    padding: 32px 43px 48px;
    text-align: center;

    ${Image} {
      max-width: 80px;
    }
    ${Tit} {
      margin-top: 8px;
      display: block;
    }
    .dec {
      margin-top: 16px;
      line-height: 1.7;

      strong {
        font-weight: 400;
        color: #1cabe2;
      }
    }
    ${ButtonMoreView} {
      margin-top: 24px;
    }
  }

  ${breakpoint(`tablet`)} {
    flex-wrap: wrap;
    & > li {
      width: 100%;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;
const BiltList = styled.ul`
  margin-top: 48px;
  padding-left: 24px;

  &.blit-list {
    li {
      margin-bottom: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .dec-wrap {
        width: calc(100% - 153px);
      }

      &:last-child {
        margin-bottom: 0;
      }
      ${Tit} {
        display: block;
        margin-bottom: 8px;
      }
      .c-blue {
        color: #1cabe2;
      }
    }
  }
  ${breakpoint(`tablet`)} {
    padding-left: 0;
    &.blit-list {
      & > li {
        .dec-wrap {
          width: 100%;
        }
        ${ButtonMoreView} {
          max-width: 100%;
          width: auto;
        }
      }
    }
  }
  ${breakpoint(`mobile`)} {
    &.blit-list {
      & > li {
        flex-wrap: wrap;
        .dec-wrap {
          margin-bottom: 8px;
        }
      }
    }
  }
`;
const NotiBox = styled.div`
  padding: 16px 24px;
  background-color: #f8f9fa;
  margin-top: 48px;

  small {
    font-size: 14px;
  }
`;
const PopContent = styled.div`
  padding-bottom: 96px;
  .top-dec {
    margin-top: 32px;
  }

  ${ButtonMoreView} {
    max-width: 143px;
    width: 100%;
    line-height: 42px;
    height: 44px;
    font-size: 16px;
  }
`;

const ReceiptPopup: FC<ReceiptProps> = ({ onRequestClose, ...props }) => {
  const userStore = useUserStore();
  return (
    <Modal onRequestClose={onRequestClose} maxWidth="880px" {...props}>
      <PopCcontainer>
        <PopupTitle>
          <h2>
            <Tit
              size="s3"
              color="sky"
              dangerouslySetInnerHTML={{ __html: props.PopTitle }}
            />
          </h2>
          <BtnClose onClick={() => onRequestClose()}>
            <span className="a11y">닫기</span>
          </BtnClose>
        </PopupTitle>
        <PopContent>
          <p className="top-dec">
            아래의 방법으로 신청하시면 더욱 편리하게 이용하실 수 있습니다.
          </p>
          <TopFlex>
            <li>
              <div className="box">
                <Image pcSrc={ReceiptObj1} mobileSrc={ReceiptObj1} />
                <Tit size="s4">개인 후원자</Tit>
                <p className="dec">
                  유니세프 웹사이트 로그인 후{` `}
                  <strong>영수증 신청</strong>
                </p>
                {userStore.isLoggedIn() ? (
                  <ButtonMoreView
                    mode="sky"
                    onClick={() => navigate(`/mypage/my-receipt`)}
                  >
                    바로가기
                  </ButtonMoreView>
                ) : (
                  <ButtonMoreView
                    mode="sky"
                    onClick={() =>
                      navigate(`/auth/login?redirectTo=/mypage/my-receipt`)
                    }
                  >
                    바로가기
                  </ButtonMoreView>
                )}
              </div>
            </li>
            <li>
              <div className="box">
                <Image pcSrc={ReceiptObj2} mobileSrc={ReceiptObj2} />
                <Tit size="s4">법인 및 병원 등 사업장</Tit>
                <p className="dec">
                  <b>1. ARS (02-737-1004) 신청</b>
                  <br />
                  유니세프한국위원회 대표번호 ARS를 통해 신청 가능합니다.{' '}
                  <strong>(연중 상시 24시간 이용 가능)</strong>
                  <br />
                  <b>2. 이메일 (donor@unicef.or.kr) 신청</b>
                  <br />
                  신청 시 사업자명, 사업자등록번호, 연락처, 영수증
                  수취방법(우편, 이메일, 팩스)을 기재해 주세요.
                </p>
              </div>
            </li>
          </TopFlex>
          <BiltList className="blit-list">
            <li>
              <div className="dec-wrap">
                <Tit size="s4">아직 유니세프 웹사이트 아이디가 없으세요?</Tit>
                <p>
                  <span className="c-blue">
                    회원가입 &gt; 마이페이지 &gt; 기부금 영수증{` `}
                  </span>
                  메뉴에서 신청
                </p>
              </div>
              {userStore.isLoggedIn() ? (
                <ButtonMoreView
                  mode="sky"
                  onClick={() => navigate(`/mypage/my-receipt`)}
                >
                  바로가기
                </ButtonMoreView>
              ) : (
                <ButtonMoreView
                  mode="sky"
                  onClick={() => navigate(`/auth/join`)}
                >
                  바로가기
                </ButtonMoreView>
              )}
            </li>
            <li>
              <div className="dec-wrap">
                <Tit size="s4">후원내역이 보이지 않으세요?</Tit>
                <p>
                  <span className="c-blue">
                    마이페이지 &gt; 후원내역 연결하기
                  </span>
                  {` `}
                  (후원 시 등록한 정보로만 가능)
                </p>
              </div>
              {userStore.isLoggedIn() ? (
                <ButtonMoreView
                  mode="sky"
                  onClick={() => navigate(`/mypage/support-history`)}
                >
                  바로가기
                </ButtonMoreView>
              ) : (
                <ButtonMoreView
                  mode="sky"
                  onClick={() =>
                    navigate(`/auth/login?redirectTo=/mypage/support-history`)
                  }
                >
                  바로가기
                </ButtonMoreView>
              )}
            </li>
          </BiltList>
          <NotiBox>
            <small>
              ※ 주민등록번호 13자리 및 사업자번호 10자리가 등록되어 후원 중인
              경우, 국세청 홈택스 `전자기부금영수증` 페이지에서 기부 내역 조회
              및 영수증 발급이 가능합니다.{' '}
              <a href="https://www.hometax.go.kr/" target="_new">
                ▷ <span className="c-blue">[국세청 홈택스 바로가기]</span>
              </a>
            </small>
          </NotiBox>
        </PopContent>
      </PopCcontainer>
    </Modal>
  );
};

export default ReceiptPopup;
