import Container from '../Container';
import Section from '../Section';
import headingPattern from '@/assets/img/layout_heading_pattern@2x.png';
import { breakpoint } from '@/helpers/BreakpointHelper';
import styled from 'styled-components';

export const NullSection = styled(Section)<{
  hideGnb?: boolean;
}>`
  height: 150px;

  ${breakpoint(`tablet`)} {
    height: 70px;
  }
  ${breakpoint(`mobile`)} {
    height: 56px;
  }
`;

export const HeadingSection = styled(Section)`
  height: 200px;
  color: #fff;
  ${Container} {
    background-image: url(${headingPattern});
    background-size: contain;
    background-position: right center;
    background-repeat: no-repeat;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .heading-container {
    width: 100%;
    max-width: 1160px;
    margin: 0 auto;
    position: relative;

    .header-dec {
      line-height: 1.6;
      letter-spacing: -1.2px;
      margin-top: 6px;
      font-size: 20px;
      margin-left: 3px;
    }
    .BreadcrumbList {
      position: absolute;
      right: 0;
      top: -10px;
      & > li {
        color: #f8f9fa;
        a {
          color: #f8f9fa;
        }

        &:last-child {
          a {
            color: #fff;
          }
        }
      }
    }
    ${breakpoint(1280)} {
      padding: 0 20px;
      .BreadcrumbList {
        right: 20px;
      }
    }
  }
  ${breakpoint(`mobile`)} {
    ${Container} {
      padding: 0 20px;
      background-size: 260px;
      background-position: right bottom;
    }
    .heading-container {
      padding: 0;
      .header-dec {
        font-size: 16px;
      }
    }
  }
`;

export const IdContainer = styled.div`
  /* 하위 아이템은 무조건 section 이여야함 */
  position: relative;
  padding-bottom: 240px;

  ${breakpoint(`tablet`)} {
    padding-bottom: 120px;
  }

  &.nonePadding {
    padding-bottom: 0 !important;
    /* border-bottom: 1px solid #e5e6e8; */
  }

  &:not(.nonePadding) > section:last-child {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
`;
