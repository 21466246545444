import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';

// 후원페이지 주소들 (GTM 적용 영역)
export const PAGE_DONATE = `/donate`;
export const PAGE_DONATE_IG = `/involve/individual/ig/form`;
export const PAGE_DONATE_ENDVIOLENCE_EN = `/what-we-do/for-every-child/endviolence/en/donate`;
export const PAGE_DONATE_ONMYMIND_EN = `/what-we-do/for-every-child/onmymind/en/donate`;

// 후원 분야
export const SUPPORT_TERM_REGULAR = `FM01`;
export const SUPPORT_TERM_ONCE = `FM02`;

export const SUPPORT_TERMS = {
  [SUPPORT_TERM_ONCE]: `일시 후원`,
  [SUPPORT_TERM_REGULAR]: `정기 후원`,
};

// 후원자 유형
export enum ENUM_SUPPORTER_TYPE {
  person = `person`,
  group = `group`,
  corporation = `coperation`, // 서버에서 coperation으로 넘어옴
}
export const SUPPORTER_TYPES = {
  [ENUM_SUPPORTER_TYPE.person]: `개인`,
  [ENUM_SUPPORTER_TYPE.group]: `단체`,
  [ENUM_SUPPORTER_TYPE.corporation]: `법인`,
};

export const MEMBER_TYPE_CORPORATION = `CO`;
export const MEMBER_TYPE_HOSPITAL = `HO`;
export const MEMBER_TYPE_INDIVIDUAL_BUSINESS = `IB`;
export const MEMBER_TYPE_SCHOOL = `SCH`;
export const MEMBER_TYPE_CLUB = `CL`;
export const MEMBER_TYPE_RELIGION = `RE`;
export const MEMBER_TYPE_ETC = `ORE`;
// 개인
export const MEMBER_TYPE_INDIVIDUAL = `IN`;
// 회원(후원자) 상세 유형

export const MEMBER_TYPES = {
  // 법인
  [ENUM_SUPPORTER_TYPE.corporation]: {
    [MEMBER_TYPE_CORPORATION]: `기업`,
    [MEMBER_TYPE_HOSPITAL]: `병원`,
    [MEMBER_TYPE_INDIVIDUAL_BUSINESS]: `개인사업자`,
  },
  // 단체
  [ENUM_SUPPORTER_TYPE.group]: {
    [MEMBER_TYPE_SCHOOL]: `학교`,
    [MEMBER_TYPE_CLUB]: `동호회`,
    [MEMBER_TYPE_RELIGION]: `종교단체`,
    [MEMBER_TYPE_ETC]: `기타`,
  },
};
// 후원자 상세 유형 맵

export const MEMBER_TYPE_FLATMAP = {
  [MEMBER_TYPE_INDIVIDUAL]: `개인`,
  [MEMBER_TYPE_CORPORATION]: `기업`,
  [MEMBER_TYPE_HOSPITAL]: `병원`,
  [MEMBER_TYPE_INDIVIDUAL_BUSINESS]: `개인사업자`,
  [MEMBER_TYPE_SCHOOL]: `학교`,
  [MEMBER_TYPE_CLUB]: `동호회`,
  [MEMBER_TYPE_RELIGION]: `종교단체`,
  [MEMBER_TYPE_ETC]: `기타`,
};

export type TYPE_MEMBER_TYPES = typeof MEMBER_TYPES[ENUM_SUPPORTER_TYPE.corporation] &
  typeof MEMBER_TYPES[ENUM_SUPPORTER_TYPE.group] & { IN: string };

// 후원 리워드용 상수
export const SOLICIT_CODE_RING = `CM21A`; // Promise 반지 캠페인
export const SOLICIT_CODE_BRACELET = `CM19D`; // 팔찌 캠페인
export const SOLICIT_CODE_UTBRACELET = `CM21M`; // TEAM 2021 팔찌 캠페인
export const SOLICIT_CODE_T_SHIRT = `CM21L`; // DPR 기후위기 캠페인

// 상수로 관리되는 카테고리 (코드와 연결됨)
export const SUPPORT_CATEGORY_REGULAR_WORLD = `D001`;
export const SUPPORT_CATEGORY_REGULAR_REFUGEE = `D009`;
export const SUPPORT_CATEGORY_REGULAR_AIDS = `D101`;
export const SUPPORT_CATEGORY_ONCE_WORLD = `D201`;
export const SUPPORT_CATEGORY_ONCE_IG = `D302`;
export const SUPPORT_CATEGORY_ONCE_MEMORY = `D224`;

// 상수로 관리되는 캠페인 (코드와 연결됨)
export const SOLICIT_CODE_ENDVIOLENCE = `CM17J`;
export const SOLICIT_CODE_ONMYMIND = `CM23J`;

// 전체 카테고리 가져오는 훅
export const useAllSupportCategories = (): GatsbyTypes.SupportCategory[] => {
  const {
    allSupportCategory: { nodes: supportCategories },
  } = useStaticQuery<{
    allSupportCategory: { nodes: GatsbyTypes.SupportCategory[] };
  }>(graphql`
    query {
      allSupportCategory(sort: { fields: code }) {
        nodes {
          indexNumber
          code
          solicitCode
          codeName
          categoryCode
        }
      }
    }
  `);
  return supportCategories;
};

// 후원결제에서 사용할 카테고리 가져오는 훅
export const useSupportCategoriesForPayment = (
  supportTerm?: keyof typeof SUPPORT_TERMS,
): GatsbyTypes.SupportCategory[] => {
  const supportCategories = useAllSupportCategories();
  const currentCategories = useMemo(() => {
    // 생명을 구하는 선물 제거
    const categoriesWithoutIg = supportCategories.filter(
      (item) => item.code !== SUPPORT_CATEGORY_ONCE_IG,
    );
    if (supportTerm) {
      const categoriesWithSolicitCode: GatsbyTypes.SupportCategory[] = [];
      const categoriesWithoutSolicitCode: GatsbyTypes.SupportCategory[] = [];
      // supportTerm 카테고리 filtering
      const filteredSupportTermCategories = categoriesWithoutIg.filter(
        (item) => item.categoryCode === supportTerm,
      );
      // 카테고리 분류
      filteredSupportTermCategories.forEach((category) => {
        // 나머지 카테고리
        if (category.solicitCode === null || category.solicitCode === '') {
          categoriesWithoutSolicitCode.push(category);
          // 긴급구호 카테고리
        } else {
          categoriesWithSolicitCode.push(category);
        }
      });
      // 긴급구호 카테고리 sorting
      categoriesWithSolicitCode.sort((a, b) =>
        b.indexNumber && a.indexNumber ? b.indexNumber - a.indexNumber : 0,
      );
      return [
        ...categoriesWithoutSolicitCode.slice(0, 1),
        ...categoriesWithSolicitCode,
        ...categoriesWithoutSolicitCode.slice(1),
      ];
    }

    return categoriesWithoutIg;
  }, [supportCategories, supportTerm]);

  return currentCategories;
};

// SupportCategoryCode & SolicitCode로 올바른 카테고리를 찾는 함수 (전체에서 조회)
export const useFindSupportCategory = (
  supportCategoryCode?: string,
  solicitCode?: string,
): GatsbyTypes.SupportCategory | null => {
  const supportCategories = useAllSupportCategories();
  if (!supportCategoryCode) {
    return null;
  }
  const codeMatched = supportCategories.filter(
    (category) => category.code === supportCategoryCode,
  );
  if (!codeMatched.length) {
    return null;
  }
  // 해당 코드의 카테고리가 여러개고, solicitCode 조건이 있다면 (긴급구호의 경우)
  if (codeMatched.length > 1 && solicitCode) {
    const solicitMatched = codeMatched.filter(
      (category) => category.solicitCode === solicitCode,
    );
    if (solicitMatched.length) {
      return solicitMatched[0];
    }
  }
  return codeMatched[0];
};

// 후원상품명 가져오기
export const getSupportProductName = (
  category?: GatsbyTypes.SupportCategory,
): string => {
  if (category) {
    return (
      (category.categoryCode === SUPPORT_TERM_ONCE ? `일시 - ` : `정기 - `) +
      category.codeName
    );
  }
  return ``;
};
