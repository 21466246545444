import Button, { ButtonProps } from './Button';
import { breakpoint } from '@/helpers/BreakpointHelper';
import React, { FC } from 'react';
import styled from 'styled-components';
import { useLocation } from '@reach/router';
import ROUTES, {
  getRoutePath,
  ServerRoute,
  serverToRoute,
} from '@/config/routes';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import Section from './Section';
import { isBrowser } from '@/helpers/BrowserHelper';
import GtmHelper, { SelectItemData } from '@/helpers/GtmHelper';

interface GtmButtonProps extends Omit<ButtonProps, 'onClick'> {
  onClickPreEvent: () => void;
  text: string /*버튼 텍스트 */;
  goto: string /*이동 경로 */;
  gtmInfo: SelectItemData /* gtm정보 */;
}

const ButtonWrap = styled(Section)`
  /* GNB 후원 버튼 */
  .btn-support-gnb {
    width: 160px;
    height: 64px;
    ${breakpoint(`tablet`)} {
      height: 70px;
      font-size: 16px;
    }
    ${breakpoint(`mobile`)} {
      width: 136px;
      height: 56px;
      font-size: 16px;
    }
  }
  /* 메인 후원 버튼 */
  .btn-support-main {
    ${breakpoint('mobile')} {
      padding: 0;
      width: 100%;
      height: 120px;
      line-height: 118px;
    }
  }
  /* 생명을 구하는 선물 목록 */
  .btn-support-lg-list {
    padding: 0;
    text-align: left;
  }
  /* 기념기부 > 혼자서 기념기부 */
  .btn-support-memory {
    width: 100%;
    max-width: 256px;
    margin-top: 64px;

    ${breakpoint(`mobile`)} {
      max-width: 100%;
      margin-top: 32px;
    }
  }
  /* 기념기부 > 같이하는 기념기부 */
  .btn-support-together {
  }
  /* 유산-추모기부 */
  .btn-support-legacy {
    padding: 0;
  }
`;

export const GtmButtonDonate: FC<GtmButtonProps> = ({
  children,
  text,
  goto,
  gtmInfo,
  onClickPreEvent,
  ...props
}) => {
  const {
    allMenu: { nodes: serverMenus },
  } = useStaticQuery<{
    allMenu: { nodes: ServerRoute[] };
  }>(graphql`
    query {
      allMenu {
        nodes {
          id
          level
          menuCode
          menuName
          menuUrl
          offspring {
            level
            menuCode
            menuParentCode
            menuColumnOrder
            menuName
            menuUrl
            offspring {
              level
              menuCode
              menuName
              menuParentCode
              menuUrl
            }
          }
        }
      }
    }
  `);
  const menus = [...serverMenus.map(serverToRoute), ...ROUTES];
  const location = useLocation();
  const uri = location?.pathname.replace(/\/$/, ``);
  const routePath = uri ? getRoutePath(uri, menus) : [];
  let arrBreadscrumb: string[] = ['Home'];
  routePath.map((item) => {
    arrBreadscrumb.push(item.name);
  });

  const onClickHandler = () => {
    //console.log('>>>>>> gtm preevent');
    onClickPreEvent();

    //console.log('>>>>>> gtm event');
    // 후원하기 버튼 클릭시 gtm 처리
    if (!gtmInfo.itemBreadscrumb) {
      gtmInfo.itemBreadscrumb = arrBreadscrumb.join('>');
    }
    GtmHelper.selectItem(gtmInfo);

    //후원 페이지 이동
    if (props.type !== 'submit' && goto) {
      isBrowser && navigate(goto);
    } else {
      //script;
    }
    //console.log('>>>>>> gtm postevent');
  };

  return (
    <ButtonWrap>
      <Button {...props} onClick={onClickHandler}>
        {children ? <>{children}</> : <>{text || '후원하기'}</>}
      </Button>
    </ButtonWrap>
  );
};
