import SkyArrow from '@/assets/img/arrow_ellipsis_24x24@2x.png';
import GrayArrow from '@/assets/img/arrow_gray_24x24@2x.png';
import WhiteArrow from '@/assets/img/arrow_white_24x24@2x.png';
import { breakpoint } from '@/helpers/BreakpointHelper';
import styled from 'styled-components';

// TODO : Button 컴포넌트에 icon 속성으로 아이콘 추가할수 있도록 refactor
const ButtonMoreView = styled.a<{
  mode?: 'white' | 'sky' | 'whiteTransparent' | 'skyTransparent' | 'gray';
}>`
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  box-sizing: border-box;
  min-width: 168px;
  line-height: 56px;
  font-size: 20px;
  height: 56px;
  padding: 0 50px 0 20px;
  font-weight: bold;
  position: relative;
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);

    ${({ mode }) => {
      if (mode === `white` || mode == `skyTransparent`) {
        return `
        background-image: url(${SkyArrow});
      `;
      }
      if (mode === `sky` || mode === `whiteTransparent`) {
        return `
        background-image: url(${WhiteArrow});
      `;
      }
      if (mode === `gray`) {
        return `
        background-image: url(${GrayArrow});
      `;
      }
      return `background-image: url(${SkyArrow});`;
    }}
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
  }

  ${breakpoint(1160)} {
    font-size: 1.9vw;
  }
  ${breakpoint(640)} {
    font-size: 16px;
  }

  ${({ mode }) => {
    if (mode === `sky`) {
      return `
        color: #fff; 
        border-color: #1cabe2;
        background: #1cabe2;
        transition: all 0.2s;
        &:hover {
         background-color: #374ea2;
         border-color: #374ea2;
        }
      `;
    }
    if (mode === `white` || mode === `whiteTransparent`) {
      return `
        color: #fff; 
        border-color: #fff;
      `;
    }
    if (mode === `skyTransparent`) {
      return `
        color: #1cabe2; 
        border-color: #1cabe2;
        background: transparent;
        transition: all 0.2s;
        &:hover {
          background-color: #1cabe2;
          color: #fff;
          &:after {
            background-image: url(${WhiteArrow});
          }
        }
      `;
    }
    if (mode === `gray`) {
      return `
        color: #828385; 
        border-color: #b7b8ba;
        background: #fff;
        &:hover {
          border-color:#1cabe2;
          background-color: #1cabe2;
          color: #fff;
          &:after {
            background-image: url(${WhiteArrow});
          }
        }
      `;
    }
    return `
      color: #1cabe2; 
      border-color: #1cabe2;
    `;
  }}
`;

export default ButtonMoreView;
