export const BANK_MAP = {
  '039': `경남은행`,
  '034': `광주은행`,
  '261': `교보증권`,
  '003': `기업은행`,
  '012': `농.축협`,
  '031': `대구은행`,
  '267': `대신증권`,
  '055': `도이치은행`,
  '287': `메리츠증권`,
  '238': `미래에셋대우`,
  '290': `부국증권`,
  '032': `부산은행`,
  '061': `비엔피파리바은행`,
  '064': `산림조합중앙회`,
  '002': `산업은행`,
  '240': `삼성증권`,
  '045': `새마을금고`,
  '007': `수협은행`,
  '053': `시티은행`,
  '291': `신영증권`,
  '278': `신한금융투자`,
  '088': `신한은행`,
  '048': `신협`,
  '020': `우리은행`,
  '071': `우체국`,
  '209': `유안타증권`,
  '280': `유진투자증권`,
  '265': `이베스트투자증권`,
  '050': `저축은행`,
  '037': `전북은행`,
  '057': `제이피모간체이스은행`,
  '035': `제주은행`,
  '062': `중국공상은행`,
  '090': `카카오뱅크`,
  '089': `케이뱅크`,
  '292': `케이프투자증권`,
  '264': `키움증권`,
  '270': `하나금융투자`,
  '081': `하나은행`,
  '262': `하이투자증권`,
  '027': `한국씨티은행`,
  '293': `한국증권금융`,
  '243': `한국투자증권`,
  '294': `한국포스증권`,
  '269': `한화투자증권`,
  '263': `현대차증권`,
  '060': `BOA은행`,
  '279': `DB금융투자`,
  '054': `HSBC은행`,
  '004': `KB국민은행`,
  '218': `KB증권`,
  '011': `NH농협은행`,
  '247': `NH투자증권`,
  '289': `NH투자증권`,
  '023': `SC제일은행`,
  '266': `SK증권`,
};

export const PAYTYPE_REGULAR_AUTO_BANKING = `FA04`;
export const PAYTYPE_REGULAR_CARD = `FA05`;
export const PAYTYPE_REGULAR_NAVERPAY = `FANP`;
export const PAYTYPE_ONCE_CARD = `FG11`;
export const PAYTYPE_ONCE_PHONE = `FG07`;
export const PAYTYPE_ONCE_BANKING = `FG12`;
export const PAYTYPE_ONCE_NAVERPAY = `FGNO`;

export const PAYMENT_TYPE_NAMES: { [key: string]: string } = {
  [PAYTYPE_REGULAR_AUTO_BANKING]: `자동이체`,
  [PAYTYPE_REGULAR_CARD]: `신용카드`,
  [PAYTYPE_REGULAR_NAVERPAY]: `네이버페이`,
  [PAYTYPE_ONCE_CARD]: `신용카드`,
  [PAYTYPE_ONCE_PHONE]: `휴대폰결제`,
  [PAYTYPE_ONCE_BANKING]: `무통장`,
  [PAYTYPE_ONCE_NAVERPAY]: `네이버페이`,
};

// 이니시스 결제수단 값 매핑
export const INICIS_PAY_METHOD_PC_MAP: {
  [key: string]: 'Card' | 'HPP' | 'VBank';
} = {
  [PAYTYPE_ONCE_CARD]: `Card`,
  [PAYTYPE_ONCE_PHONE]: `HPP`,
  [PAYTYPE_ONCE_BANKING]: `VBank`,
};

// 이니시스 결제수단 값 매핑
export const INICIS_PAY_METHOD_MOBILE_MAP: {
  [key: string]: 'CARD' | 'VBANK' | 'MOBILE';
} = {
  [PAYTYPE_ONCE_CARD]: `CARD`,
  [PAYTYPE_ONCE_PHONE]: `MOBILE`,
  [PAYTYPE_ONCE_BANKING]: `VBANK`,
};

// 정기후원 계좌 상태값
export const REGCD_NEW = `FT00`; // 신규
export const REGCD_CANCEL = `FT01`; // 해지
export const REGCD_MODIFY = `FT02`; // 변경
export const REGCD_FAIL = `FT03`; // 실패
export const REGCD_COMPLETE = `FT11`; // 등록완료
