import CryptoJS from "crypto-js";

const padKey = (key: string) => {
  return key.padEnd(32, " "); // AES256은 key 길이가 32자여야 함
}
const getKey = (key: string) => {
  return padKey(key)
}
const getIv = (key: string) => {
  return padKey(key.substr(0, 16));
}

const AesCryptoHelper = {
  encodeByAES256: (key: string, data: string) => {
    const cipher = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(getKey(key)), {
      iv: CryptoJS.enc.Utf8.parse(getIv(key)),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    });
    return cipher.toString();
  },
  decodeByAES256: (key: string, data: string) => {
    const cipher = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(getKey(key)), {
      iv: CryptoJS.enc.Utf8.parse(getIv(key)),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    });
    return cipher.toString(CryptoJS.enc.Utf8);
  },
}
export default AesCryptoHelper;